@import "colors";

@media (prefers-reduced-motion) {
    * {
        // Disable any eventual animation if the user has requested it.
        animation: none !important;
    }

    html {
        cursor: url("dustysword.png") 4 4, auto;
    }
}

html {
    cursor: url("dustysword.ani") 4 4, url("dustysword.png") 4 4, url("dustysword.gif") 4 4, auto;
}

a {
    color: #FFF;
}

a:visited {
    color: #CCC;
}

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 1000px;
    width: 100%;

    background-color: #000;
    background-image: url("bg.png");
    background-position: bottom;
    background-attachment: fixed;
    background-repeat: repeat-x;
    color: #FFF;

    font: 16px/1.6em sans-serif;
    
    h1 {
        font-size: 2em;
    }

    h1, h2, h3, h4, h5 {
        line-height: initial;
    }

    @media screen and (min-width: 1000px) {
        margin-left: auto;
        margin-right: auto;
    }

    header, footer {
        margin: 8px;

        nav > * {
            display: inline-block;
            margin: 0 8px 0 8px;
        }
    }
}

main, header, footer {
    background-color: rgba(0, 0, 0, 0.4);
}

.page-content {
    padding: 16px;
}

.box {
    border: 1px dotted $ponk;
    padding: 16px;
    margin: 16px;
    min-width: 250px;
}

.box.solid {
    @include emblem;
    border-style: solid;
    border-width: 8px;
}

.box h1 {
    margin: 0;
}

q {
    font-style: italic;
}

.float-clear {
    clear: both;
}

@import "homepage";
@import "post";
@import "characters";
@import "artwork";
@import "highlighter";