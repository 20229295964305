@import "colors";

.artwork {
    .artwork-header {
        margin-bottom: 16px;
        border: 8px solid;
        @include emblem;
        padding: 8px;

        .artwork-title {
            float: left;
            font-size: 2em;
            line-height: normal;
            margin: 0;
        }
        .artwork-meta {
            float: right;
            text-align: right;
            line-height: normal;
            margin: 0;
        }
    }
    
    img {
        max-width: 100%;
    }
}

.artwork-list-content {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;

    .artwork-list-entry {
        width: 200px;
        height: 200px;
        margin: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include emblem;
        
        // To allow absolute positioning to be relative to this container
        position: relative;
        
        
        .artwork-list-meta {
            display: block;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);

            left: 5px;
            bottom: 5px;
            padding: 5px;

            height: 3.3em;
            line-height: 1.1em;
        }
        .artwork-list-name {
        }
    
        .artwork-list-artist {
        }
    
        .artwork-list-date {
        }
    
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }
}