@use "sass:color";

$ponk: #EF0178;
$deep-dark-blue: #0f0834;
$blue: #2c5477;
$orange: #F1AB6A;
$purple: #7e3279;

@mixin emblem {
    border-image: linear-gradient(0deg, $purple 10%, $ponk 100%) 1;
    background-image: linear-gradient(0deg, darken($purple, 20%) 10%, darken($ponk, 20%) 100%);
}