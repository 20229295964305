.home_content {
    width: 100%;

    .animated-pixelart {
        display: inline-block;
        margin: 16px;
        image-rendering: crisp-edges;

        @media (prefers-reduced-motion) {
            display: none;
        }
    }

    #links {
        margin-bottom: 0;

        @media screen and (min-width: 1000px) {
            width: 400px;
            float: right;
        }
    }

    #post-header {
        grid-area: postheader;
    }

    #posts {
        .box:last-of-type {
            margin-bottom: 0;
        }
        padding-bottom: 16px;
    }

    .post {
        h1 {
            float: left;
            font-size: 2em;
        }
        .post-meta {
            float: right;
            margin: 0;
            text-align: right;
            line-height: normal;
        }
    }
    
    #me {
        .avatar-image {
            float: left;
            margin-right: 16px;

            @media screen and (max-width: 600px) {
                float: none;
            }
        }
    }

    #me img {
        height: 250px;
        width: 250px;
    }
}

