@import "faketable";

.character-box {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "infobox character-text"
        "infobox ."
        "infobox fun-fact";
    column-gap: 0;
    row-gap: 0;

    &:nth-of-type(even) {
        grid-template-areas:
            "character-text infobox"
            ". infobox"
            "fun-fact infobox";
    }

    .box.solid {
        grid-area: infobox;
        display: inline-grid;
        grid-template-columns: 250px;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
            "name"
            "image"
            "facts"
            "quote";
        column-gap: 0;
        row-gap: 1em;

        .character-art {
            grid-area: image;
        }

        .character-quote {
            grid-area: quote;
        }

        .character-facts {
            grid-area: facts;
        }
    }

    .character-text {
        grid-area: character-text;
        padding: 0 16px 0 16px;
    }

    .character-fun-fact {
        grid-area: fun-fact;
    }

    .character-art-credits {
        font-size: x-small;
    }
}