@import "colors";

article.post {
    header {
        border: 8px solid;
        @include emblem;
        padding: 8px;

        h1 {
            margin: 0;
        }

        p.post-meta {
            margin: 16px 0 0 0;
        }
    }

    .post-content {
        margin: 16px;

        img {
            max-width: 100%;
        }
    }

    border: 1px dotted $ponk;
    // Grey is easier on the eyes than black.
    background-color: #1c1c1c;
}