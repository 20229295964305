// Firefox 83 is causing some odd layout issues with tables in grids, so I'll just recreate them.

.fake-table {
    display: flex;
    flex-direction: column;

    .fake-table-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid #FFF;
        
        &:nth-last-child(1) {
            border-bottom: 1px solid #FFF;
        }

        > * {
            margin: 4px;
        }

        .fake-table-th {
            grid-column: 1 / span 1;
            font-weight: bold;
        }

        .fake-table-td {
            grid-column: 2 / span 1;
        }
    }
}