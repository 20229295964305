pre {
    // Undo a bunch of readability changes, to make it more compact.
    // Clone VS Code style, to be at it.
    line-height: initial;
    overflow-x: auto;
    background-color: #1f1f1f;
    color: #cccccc;
    border: 1px solid #454545;
    padding: 8px;
}

.highlighter-rouge {
    &.language-yaml {
        .na, .kc {
            color: #569CD6;
        }
        .s2, .s {
            color: #CE9178;
        }
    }
}